import React from 'react'
import Layout from '../../components/Layout'
import Scanner from '../../components/form/Scanner'
import Text from '../../components/common/Text'
import Space from '../../components/common/Space'
import { Wrapper } from '../../components/pageSlices/ScannerBanner'

export default function index() {
  return (
    <Layout>
      <Wrapper hasTopPadding hasBottomPadding>
        <Text heading2 center>
          How Accessible Is Your Site?
        </Text>
        <Space height={32} />
        <Text center>Scan your website for free and find out your Accessibility Score.</Text>
        <Space height={32} />
        <Scanner partner />
      </Wrapper>
    </Layout>
  )
}
